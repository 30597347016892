import React, { Component, useEffect, useState} from "react";
import { Layout, Row, Col, Button, Icon } from "antd";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import FixedSideMenu from "../FixedSideMenu";
import HorizontalDefault from "../Topbar/HorizontalDefault";
import HorizontalDark from "../Topbar/HorizontalDark";
import InsideHeader from "../Topbar/InsideHeader";
import AboveHeader from "../Topbar/AboveHeader";
import BelowHeader from "../Topbar/BelowHeader";
import { version } from "util/config";
import Topbar from "../Topbar";
import { footerText, disclamer, p3, expire } from "util/config";
import App from "routes/index";

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification";
import { auth, database } from "../../firebase/firebase";

const { Content, Footer,  } = Layout;

export class MainApp extends Component {

  constructor(props) {
		super(props);
		this.state = {
			user: {},
      collapsed: false
		};
	}

  componentWillMount() {
    var _this = this;
    if(window.innerWidth < 768){
      this.setState({collapsed: true});
    }
    auth.onAuthStateChanged((user) => {
			if (user) {
        database
			.collection('users')
			.where('email', '==', user.email)
			.get()
			.then(function (querySnapshot) {
				querySnapshot.forEach(function (doc) {
				
					// doc.data() is never undefined for query doc snapshots
					//console.log(doc.data())
					//console.log(doc.id, ' => ', doc.data());
					_this.setState({ user: doc.data() 

          });})});
      }});
  }

  getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
      case NAV_STYLE_DEFAULT_HORIZONTAL:
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      case NAV_STYLE_BELOW_HEADER:
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return '';
    }
  };

  getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return <Topbar />;
    }
  };

  render() {
    const { match, width, navStyle } = this.props;
    const doctor = "https://firebasestorage.googleapis.com/v0/b/doctualiza.appspot.com/o/doctor.png?alt=media&token=ccd7c0f9-99b9-457e-98bc-f4dc460d2918";
    const doctora = "https://firebasestorage.googleapis.com/v0/b/doctualiza.appspot.com/o/doctora.png?alt=media&token=ccd7c0f9-99b9-457e-98bc-f4dc460d2918";

    return (
      <Layout className="gx-app-layout">

        <FixedSideMenu collapsed={this.state.collapsed}/>
        <Layout>
          {/* this.getNavStyles(navStyle) */}
          <img className="full-image" src={require('assets/images/SIEM_HOME.jpg')} alt="PiSA Logo" />

          <div className="trans-back">
          <Row>
							<Col xl={14} lg={14} md={14} sm={24} xs={24}>
						</Col>
						<Col xl={10} lg={10} md={10} sm={24} xs={24}>
				
							<Row>
              <Col xl={0} lg={0} md={2} sm={2} xs={2} className='no-padding'>
              <Button
              className="collapse-button"
            type="text"
            icon={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
            onClick={() => this.setState({ collapsed: !this.state.collapsed })}
            style={{
              zIndex: 100,
              fontSize: '16px',
              width: 50,
              height: 40,
            }}
          /></Col>
								<Col xl={20} lg={20} md={18} sm={18} xs={18} className='no-padding'>
              
								<div className="elements-left">
									{(Object.keys(this.state.user).length > 0)?<p id="mail-name"><span className='dark-blue-text name-text'>{(this.state.user === undefined || this.state.user === null) ? "":this.state.user.name+" "+this.state.user.paternal_lastname+" "+this.state.user.maternal_lastname}</span><br></br>
									<span className='light-blue-text'>{(this.state.user !== undefined && this.state.user !== null)? this.state.user.email:""}</span></p>:<p></p>}
								</div>
								</Col>
								<Col xl={4} lg={4} md={4} sm={4} xs={4}>
								<div className='image-container'>
									{(this.state.user !== undefined || this.state.user !== null)?((this.state.user.sex === "Femenino" ?<img className="profile-image" src={doctora} />:<img className="profile-image" src={doctor}/>)):<img className="profile-image" src={doctor}/>}
								</div>
								</Col>
							</Row>
						</Col>
            <div className='hl-side-margin'></div>
				
						</Row>

            </div>

		
          <Content className={`gx-layout-content ${this.getContainerClass(navStyle)}`}>
            <App match={match} />
            <br /><br /><br /><br /><br /><br />
            <Footer className="blue-footer">
              <div className="blue-footer">
                <Row className='bottom-links-footer'>
                  <div className='bottom-links-center'>
                    <a className="gx-login-footer-link" href="https://www.doctualiza.com.mx/soporte" target="_blank">Soporte</a>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <a className="gx-login-footer-link" href="">·</a>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <a className="gx-login-footer-link" href="https://www.doctualiza.com.mx/formulariocontacto" target="_blank">Enviar correo electrónico</a>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <a className="gx-login-footer-link" href="">·</a>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <a className="gx-login-footer-link" href="https://www.doctualiza.com.mx/aviso" target="_blank">Aviso de Privacidad</a>

                  </div>
                </Row>
                <Row>

										<Col xl={4} lg={4} md={0} sm={0} xs={0}></Col>
                  <Col xl={3} lg={3} md={11} sm={11} xs={11}>  
                  </Col>
                  
                  <Col xl={3} lg={3} md={10} sm={10} xs={10}>
                  <img className="logo-inter-footer" alt="example" src={require('assets/images/intersistemas.png')} />
                  </Col>
                  <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                    <p className="white-text">Desarrollado por <span className="bold-futura">Intersistemas, S.A de C.V</span><br></br>
                    Aguiar y Seijas 75<br></br>Colonia Lomas de Chapultepec<br></br>Alcaldía Miguel Hidalgo<br></br>
                    CDMX, CP.11000<br></br>intersistemas@intersistemas.com.mx
                    <br></br>www.intersistemas.com<br></br></p>
                  </Col>
                  <Col xl={4} lg={4} md={0} sm={0} xs={0}></Col>
                  </Row>
                <p className="footer-bottom-text">Para uso exclusivo para Profesionales de la Salud · Copyright © PiSA</p>

              </div>
              <span id="version-code">
							{version}</span>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { width, navStyle } = settings;
  return { width, navStyle };
};

export default connect(mapStateToProps)(MainApp);
