import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { auth, database } from '../../firebase/firebase';
import UserProfile from 'containers/Sidebar/UserProfile';

const { Sider } = Layout;

const FixedSideMenu = ({collapsed}) => {
  const [menuMessage, setMenuMessage] = useState('<p>Cargando...</p>');

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching message...');
        const collection = await database.collection('menu_message').get();
        console.log('Collection: ', collection);  
        if(collection.docs.length === 0) {
          setMenuMessage('No message found');
          return;
        }else{
          console.log('Collection: ', collection.docs[0]);
          const doc = collection.docs[0];
        if (doc.exists) {
          var date = new Date().toLocaleDateString("es-MX", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).toString();
          setMenuMessage('<p>'+date+'<p>'+doc.data().content);
        } else {
          setMenuMessage('Bienvenido');
        }
        }
      } catch (error) {
        console.error("Error fetching message: ", error);
        setMenuMessage('Failed to load message');
      }
    };

    fetchData();
  }, []);

  return (
     <Sider className="fixed-side-menu"  
     breakpoint="lg"
     collapsible
     
     style={{ display: 'block' }}
     collapsed={collapsed}
  
     collapsedWidth={0} >
      <div className="logo">
        <img src={require('assets/images/logo_login.png')} alt="Logo" className="logo-image" />
      </div>
      <div className="hl-side"></div>
      <br /><br />

      <Menu mode="vertical" defaultSelectedKeys={['1']} className="menu">
        <Menu.Item className='custom-menu blue-back' key="1" icon={<UserOutlined />}>
        <a className="blue-link" href="/"> <div><img className="menu-icon" src={require('assets/images/icon-square.png')} alt="Icon Square" /><span className='fontFutura'> Mi Perfil</span></div></a>
        </Menu.Item>
        <br />
        <Menu.Item className='custom-menu no-back' key="2" icon={<UserOutlined />}>
         <a className="gray-link" href="https://www.doctualiza.com.mx/soporte" target='_blank'> <div><img className="menu-icon" src={require('assets/images/icon-support.png')} alt="Icon Support" /><span> Soporte</span></div></a>
        </Menu.Item>
        <br />
        <Menu.Item className='custom-menu no-back' key="3" icon={<UserOutlined />}>
        <a className="gray-link" href="https://www.doctualiza.com.mx/acerca-pisa"  target='_blank'> <div><img className="menu-icon" src={require('assets/images/icon-note.png')} alt="Icon Note" /><span> Acerca de </span></div>
        </a>
        </Menu.Item>
        <br />
        <Menu.Item className='custom-menu no-back' key="4" icon={<LogoutOutlined />}>
        <div class="icon user-o">          <UserProfile />
        </div>
        </Menu.Item>
      </Menu>

      <div className="bottom-section">
        <div className="welcome-message">
          <div className="numberCircle"><img id='icon-news' src={require('assets/images/icon_news.png')} alt="News Icon" /></div>
          <div className='futura-font' dangerouslySetInnerHTML={{ __html: menuMessage }} />

        </div>
        
      </div>
      <div className="vl-sidebar"></div>
    </Sider>
  );
};

export default FixedSideMenu;
